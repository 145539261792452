<template>
  <v-container grid-list-md class="grey lighten-4 fill-height">
    <v-layout row wrap>
      <v-flex xs4> </v-flex>
      <v-flex xs8 style="height: calc(100vh - 132px)"> </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { ZoomMtg } from "@zoomus/websdk";
import { zoomApiKeys } from "../../../library/helpers";

// ZoomMtg.setZoomJSLib("https://source.zoom.us/1.8.0/lib", "/av");
ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.5/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

export default {
  data() {
    return {
      conference: {},
    };
  },
  computed: {},
  mounted() {
    this.get();
    window.onbeforeunload = function() {
      return "Please leave the meeting first";
    };
  },
  methods: {
    get() {
      this.$rest
        .get(`/api/conference/${this.$route.params.meetingId}`)
        .then(({ data }) => {
          this.conference = data;
          this.startMeeting(
            this.conference.zoom.meeting_id,
            this.conference.zoom.password
          );
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
            this.$router.push({
              name: "live-class",
            });
          }
        });
    },
    startMeeting(meetingId, password) {
      if (!meetingId) return false;
      let user = this.$auth.user();
      const payload = {
        apiKey: zoomApiKeys(this.$auth.company("id")),
        meetingNumber: parseInt(meetingId),
        leaveUrl: location.origin + "/live-class",
        userName: user.full_name,
        userEmail: "",
        passWord: password, // if required
        role: this.$auth.loggedInRole().slug === "student" ? 0 : 1, // 1 for host; 0 for attendee
      };
      // console.log(payload);
      this.$rest.post(`/api/meeting/${meetingId}`, payload).then((res) => {
        ZoomMtg.init({
          leaveUrl: payload.leaveUrl,
          isSupportAV: true,
          inviteUrlFormat: "",
          disableCallOut: true, //optional
          disableInvite: true,
          debug: false,
          success: function() {
            ZoomMtg.inMeetingServiceListener("onUserJoin", function(data) {
              console.log(data);
            });

            ZoomMtg.inMeetingServiceListener("onUserLeave", function(data) {
              console.log(data);
            });

            ZoomMtg.inMeetingServiceListener("onUserIsInWaitingRoom", function(
              data
            ) {
              console.log(data);
            });

            ZoomMtg.inMeetingServiceListener("onMeetingStatus", function(data) {
              // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
              console.log(data);
            });

            ZoomMtg.join({
              signature: res.data,
              meetingNumber: payload.meetingNumber,
              userName: payload.userName,
              apiKey: payload.apiKey,
              userEmail: payload.userEmail,
              passWord: payload.passWord,
              success: (success) => {
                ZoomMtg.showInviteFunction({ show: false });
                console.log(success);
              },
              error: (error) => {
                console.log(error);
              },
            });
          },
        });
      });
    },
  },
};

// ZoomMtg.init({
//     debug: true, //optional
//     leaveUrl: 'http://www.zoom.us', //required
//     webEndpoint: 'PSO web domain', // PSO option
//     showMeetingHeader: false, //option
//     disableInvite: false, //optional
//     disableCallOut: false, //optional
//     disableRecord: false, //optional
//     disableJoinAudio: false, //optional
//     audioPanelAlwaysOpen: true, //optional
//     showPureSharingContent: false, //optional
//     isSupportAV: true, //optional,
//     isSupportChat: true, //optional,
//     isSupportQA: true, //optional,
//     isSupportCC: true, //optional,
//     screenShare: true, //optional,
//     rwcBackup: '', //optional,
//     videoDrag: true, //optional,
//     sharingMode: 'both', //optional,
//     videoHeader: true, //optional,
//     isLockBottom: true, // optional,
//     isSupportNonverbal: true, // optional,
//     isShowJoiningErrorDialog: true, // optional,
//     inviteUrlFormat: '', // optional
//     loginWindow: {  // optional,
//         width: 400,
//         height: 380
//     },
//     meetingInfo: [ // optional
//         'topic',
//         'host',
//         'mn',
//         'pwd',
//         'telPwd',
//         'invite',
//         'participant',
//         'dc'
//     ],
//     disableVoIP: false, // optional
//     disableReport: false, // optional
// });
</script>
<style lang="scss"></style>
